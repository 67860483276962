<template>
  <v-card rounded="0">
    <v-card-title class="text-h5 text-uppercase panelheader">
      {{ tLabel("Gate-in") }}
      <v-spacer />
      <v-icon large @click="discardGateIn">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-form ref="gateInForm" v-model="valid">
        <v-row align="center">
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead class="header">
                  <tr>
                    <!-- <th class="text-left">id</th> -->
                    <th class="text-left">{{ tLabel("Unit") }}</th>
                    <th class="text-left">{{ tLabel("Owner") }}</th>
                    <th class="text-left">{{ tLabel("Unit Type") }}</th>
                    <th class="text-left">{{ tLabel("ISO Size Type") }}</th>
                    <th class="text-left">{{ tLabel("Items") }}</th>
                    <th class="text-left">{{ tLabel("WGT") }}</th>
                    <th class="text-left">{{ tLabel("Full/Empty") }}</th>
                    <th class="text-left">{{ tLabel("UNDG") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <td>{{ gateInSubmitReqObj.unit.id }}</td> -->
                    <td>{{ gateInSubmitReqObj.unit.code }}</td>
                    <td>{{ gateInSubmitReqObj.unit.owner }}</td>
                    <td>{{ gateInSubmitReqObj.unit.unitTypeCode }}</td>
                    <td>{{ gateInSubmitReqObj.unit.isoSizeType }}</td>
                    <td>{{ gateInSubmitReqObj.unit.qty }}</td>
                    <td>{{ gateInSubmitReqObj.unit.grossweight }}</td>
                    <td>
                      <div>
                        <table>
                          <tr>
                            <td v-for="fe in fullEmpties" :key="fe.value">
                              <v-icon v-if="gateInSubmitReqObj.unit.fullEmpty === fe.value" color="primary" :title="fullEmptiesMap[fe.value]">{{
                                "mdi-alpha-" + fe.value.toLowerCase() + "-box"
                              }}</v-icon>
                              <v-icon v-else :title="fullEmptiesMap[fe.value]">{{ "mdi-alpha-" + fe.value.toLowerCase() + "-box-outline" }}</v-icon>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                    <td>
                      <div>
                        <v-icon v-if="gateInSubmitReqObj.unit.dangerous === true" color="warning">mdi-alert</v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="3">
            <v-text-field
              :label="tLabel('Order')"
              v-model="gateInSubmitReqObj.unit.orderCode"
              @input="(val) => (gateInSubmitReqObj.unit.orderCode = val ? val.toUpperCase() : null)"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('Net weight')" v-model="gateInSubmitReqObj.unit.netWeight" :rules="[rules.numeric]" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="tLabel('Position')"
              v-model="gateInSubmitReqObj.unit.positionCode"
              @input="(val) => (gateInSubmitReqObj.unit.positionCode = val ? val.toUpperCase() : null)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-if="gateInSubmitReqObj.sysType !== 'UNT'" v-model="sigilliPanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
                  {{ tLabel("Sigilli") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        :label="tLabel('Seal 1')"
                        v-model="gateInSubmitReqObj.unit.seal1"
                        @input="(val) => (gateInSubmitReqObj.unit.seal1 = val ? val.toUpperCase() : null)"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :label="tLabel('Seal 2')"
                        v-model="gateInSubmitReqObj.unit.seal2"
                        @input="(val) => (gateInSubmitReqObj.unit.seal2 = val ? val.toUpperCase() : null)"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :label="tLabel('Seal 3')"
                        v-model="gateInSubmitReqObj.unit.seal3"
                        @input="(val) => (gateInSubmitReqObj.unit.seal3 = val ? val.toUpperCase() : null)"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12">
            <v-expansion-panels v-model="damagePanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
                  {{ tLabel("Damages") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="5">
                      <v-autocomplete
                        :label="tLabelRequired('Damage Type')"
                        :items="damageTypes"
                        clearable
                        v-model="damageToAdd.damageType"
                        :item-text="damageTypeRender"
                        return-object
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-text-field :label="tLabel('Position')" v-model="damageToAdd.position" @input="(val) => (damageToAdd.position = val ? val.toUpperCase() : null)" />
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="10">
                      <v-textarea :label="tLabel('Remarks')" v-model="damageToAdd.remarks" counter maxlength="255" />
                    </v-col>
                    <v-col cols="2" class="text-center">
                      <v-btn color="primary" @click="addDamage"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr>
                              <th />
                              <th class="text-left">{{ tLabel("Type") }}</th>
                              <th class="text-left">{{ tLabel("Position") }}</th>
                              <th class="text-left">{{ tLabel("Remarks") }}</th>
                            </tr>
                          </thead>
                          <tbody v-if="gateInSubmitReqObj.unit.damages.length > 0">
                            <tr v-for="damage in gateInSubmitReqObj.unit.damages" :key="damage.damageType.id + damage.position">
                              <td>
                                <v-btn icon color="primary" @click="removeDamage(damage)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </td>
                              <td>{{ damage.damageType.code }} - {{ damage.damageType.description }}</td>
                              <td>{{ damage.position }}</td>
                              <td>{{ damage.remarks }}</td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="4">{{ tLabel("No damages") }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12">
            <v-card>
              <!-- {{gateInSubmitReqObj.carrier.id}} -->
              <v-card-title class="text-uppercase font-weight-bold">
                <span>
                  <v-icon large>mdi-truck</v-icon>
                  <span class="mx-2 px-2">{{ tLabel("Transport Vehicle") }}</span>
                  <span v-if="!gateInSubmitReqObj.carrier.id && truckExistsMsg !== null" class="text-h6 text-uppercase font-weight-bold warning pa-3">{{ truckExistsMsg }}</span>
                </span>
              </v-card-title>
              <v-divider class="mx-4 warning"></v-divider>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      :label="tLabelRequired('License Plate')"
                      clearable
                      v-model="gateInSubmitReqObj.carrier.licensePlate"
                      @change="onChangeCarrierLicensePlate"
                      :rules="[rules.required]"
                      @input="(val) => (gateInSubmitReqObj.carrier.licensePlate = val ? val.toUpperCase() : null)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select :label="tLabel('Transport Company')" v-model="gateInSubmitReqObj.carrier.ownerCompanyId" :items="carriers" item-value="id" item-text="code" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12">
            <v-card>
              <!-- {{gateInSubmitReqObj.driver.id}} -->
              <v-card-title class="text-uppercase font-weight-bold">
                <span
                  ><v-icon large>mdi-account</v-icon><span class="mx-2 px-2">{{ tLabel("Driver") }}</span></span
                >
              </v-card-title>
              <v-divider class="mx-4 warning"></v-divider>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      :label="tLabelRequired('Name')"
                      v-model="gateInSubmitReqObj.driver.name"
                      :rules="[rules.required]"
                      @input="(val) => (gateInSubmitReqObj.driver.name = val ? val.toUpperCase() : null)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :label="tLabelRequired('Surname')"
                      v-model="gateInSubmitReqObj.driver.surname"
                      :rules="[rules.required]"
                      @input="(val) => (gateInSubmitReqObj.driver.surname = val ? val.toUpperCase() : null)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select :label="tLabel('Company')" v-model="gateInSubmitReqObj.driver.companyId" :items="carriers" item-value="id" item-text="code" />
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      :label="tLabel('Identity doc number')"
                      v-model="gateInSubmitReqObj.driver.identityDocNumber"
                      @input="(val) => (gateInSubmitReqObj.driver.identityDocNumber = val ? val.toUpperCase() : null)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :label="tLabel('Driver license')"
                      v-model="gateInSubmitReqObj.driver.driverLicense"
                      @input="(val) => (gateInSubmitReqObj.driver.driverLicense = val ? val.toUpperCase() : null)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :label="tLabel('Badge')"
                      v-model="gateInSubmitReqObj.driver.badge"
                      @input="(val) => (gateInSubmitReqObj.driver.badge = val ? val.toUpperCase() : null)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12">
            <ItineraryCard :itineraryVisit="gateInSubmitReqObj.unit.itineraryVisit" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea :label="tLabel('Remarks')" v-model="gateInSubmitReqObj.handlingInRemarks" counter maxlength="255" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn medium outlined class="pa-2 ma-2" @click="discardGateIn"> <v-icon class="mr-2">mdi-cancel</v-icon>{{ tLabel("Cancel") }} </v-btn>
      <v-btn color="primary" class="pa-2 ma-2" @click="doGateIn(false)" medium :disabled="!gateInSubmitReqObj.carrier.id && truckExistsMsg !== null">
        <v-icon class="mr-2">mdi-login-variant</v-icon>{{ tLabel("Gate-in") }}
      </v-btn>
      <v-btn color="primary" class="pa-2 ma-2" @click="doGateIn(true)" medium :disabled="!gateInSubmitReqObj.carrier.id && truckExistsMsg !== null">
        <v-icon class="mr-2">mdi-printer-outline</v-icon>{{ tLabel("Gate-in + Stampa") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ItineraryCard from "@/components/yard/ItineraryCard";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";
import FormatsMixins from "@/mixins/FormatsMixins";
import ToastMixin from "@/mixins/ToastMixin";
import YardEnumMixin from "@/mixins/yard/EnumMixin";

export default {
  name: "GateIn",  
  data() {
    return {
      valid: true,
      truckExistsMsg: null,
      damageTypes: [],
      damageToAdd: {},
      damagePanel: [],
      sigilliPanel: [],
      carriers: []
    };
  },
  props: {
    gateInSubmitReqObj: {
      type: Object,
    },
  },
  components: { DateTimeAdapter, ItineraryCard },
  mixins: [ToastMixin, FormatsMixins, YardEnumMixin],
  async mounted() {
    console.log("GateIn mounted gateInSubmitReqObj -----> ", this.gateInSubmitReqObj);
    this.damageTypes = await this.$api.yardData.damageTypes();
    this.carriers = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/carriers");
  },
  methods: {
    async onChangeCarrierLicensePlate() {
      // spostare logica su server + valutare utilizzo event input partendo da N caratteri
      this.truckExistsMsg = null;
      if (!this.gateInSubmitReqObj.carrier.id) {
        var roroVisitsByUnitCode = (await this.$api.yardData.getRoroVisitByLicensePlate(this.gateInSubmitReqObj.carrier.licensePlate)).data;
        if (roroVisitsByUnitCode !== null && roroVisitsByUnitCode.length > 0) {
          var advisedFound = false;
          var inYardFound = false;
          roroVisitsByUnitCode.forEach((roroVisit) => {
            if (roroVisit.status === "A") {
              advisedFound = true;
            } else if (roroVisit.status !== "D" && roroVisit.status !== "C") {
              inYardFound = true;
            }
          });
          if (advisedFound === true) {
            this.truckExistsMsg = this.tLabel("Truck already advised");
          } else if (inYardFound == true) {
            this.truckExistsMsg = this.tLabel("Truck already in yard");
          }
        }
      }
    },
    async doGateIn(doStampa) {
      const valid = this.$refs.gateInForm.validate();
      if (!valid) {
        return;
      }

      console.log("this.gateInSubmitReqObj", this.gateInSubmitReqObj);
      try {
        // this.gateInSubmitReqObj.unit.itineraryVisit = this.gateInSubmitReqObj.unit.itineraryVisit; ???

        await this.$api.yardData.gateIn(this.gateInSubmitReqObj);
        if (doStampa) {
          this.stampaInterchange(this.gateInSubmitReqObj.unit.id);
        }
        this.damagePanel = [];
        this.sigilliPanel = [];
        this.$refs.gateInForm.reset();
        this.$eventBus.$emit("gateInDone", true);
      } catch (e) {
        this.showError("Errore gate in: " + e);
      }
    },

    discardGateIn() {
      this.damagePanel = [];
      this.$refs.gateInForm.reset();
      this.$eventBus.$emit("gateInDone", false);
    },

    async stampaInterchange(id) {
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        var url = `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeInByVisitId/` + id;
        const response = await this.$api.get(url, _callConfig);
        const blob = new Blob([response], { type: "application/pdf;charset=ANSI" });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "Interchange3.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        console.error("Errore Interchange", e);
      }
    },
    damageTypeRender: (item) => item.code + " - " + item.description,
    addDamage() {
      this.gateInSubmitReqObj.unit.damages.push(JSON.parse(JSON.stringify(this.damageToAdd)));
      this.damageToAdd = {};
    },
    removeDamage(damage) {
      this.gateInSubmitReqObj.unit.damages.pop(damage);
    },
  }
};
</script>
